import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Datasheet from "../../../../content/produk/controller/videoprocessor/NOVASTAR J6/datasheet-novastar-j6.pdf";
import Content from "../../../../content/produk/controller/videoprocessor/NOVASTAR J6/Product-Page.json";
import ProductImage from "../../../../images/product/controller/videoprocessor/NOVASTAR J6/Product-Summary-Novastar-J6.jpg";
const novastarj6 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>{Content.seo.title}</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            J6 adalah <i>switcher seamless</i> berperforma
                            tinggi dari Novastar yang mengintegrasikan
                            pemrosesan video, <i>screen mosaic</i>, efek
                            transisi dan kemampuan menampilkan multi-layar. J6
                            memberikan kemampuan untuk memproses dan menerima
                            sinyal video yang kuat dan mendukung hingga delapan
                            input dengan resolusi hingga 4K@30fps dan 6{" "}
                            <i>window</i>. Di samping itu, produk ini mendukung
                            2 mode operasi; sebagai <i>splicer</i> dan{" "}
                            <i>switcher</i>. Berbasis platform FPGA berperforma
                            tinggi, J6 mendukung manajemen EDID dan pengaturan
                            warna untuk input dan output, transisi halus dari
                            berbagai sumber input beserta fade dan efek transisi
                            lainnya yang memberikan anda pengalaman visual yang
                            kaya dan fleksibel. J6 dilengkapi oleh Novastar
                            V-CAN <i>smart control software</i> dan{" "}
                            <i>event controller</i> C1 yang memungkinkan mosaik
                            layar yang kaya melalui V-CAN, C1 atau operasi panel
                            depan. Dengan kualitas gambar yang tinggi, kapasitas
                            tinggi dan mode operasi yang fleksibel J6 dapat
                            digunakan pada konferensi, eksibisi, kendali
                            panggung dan skenario lainnya.
                        </p>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

novastarj6.propTypes = {
    siteTitle: PropTypes.string,
};

novastarj6.defaultProps = {
    siteTitle: ``,
};
export default novastarj6;
